<template>
  <b-sidebar id="add-new-categoria-patrocinadores-sidebar" :visible="isInfoSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Dados do usuário</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
      </div>
      <div class="p-2">
        <p><span class="font-weight-bold">Name: </span>{{ data.name }}</p>
        <p><span class="font-weight-bold">CPF: </span>{{ data.cpf }}</p>
        <p><span class="font-weight-bold">E-mail: </span>{{ data.email }}</p>
        <p v-if="data.secretaria"><span class="font-weight-bold">Secretaria: </span>{{ data.secretaria }}</p>
        <p v-if="data.cargo"><span class="font-weight-bold">Cargo: </span>{{ data.cargo }}</p>
        <p v-if="data.crm"><span class="font-weight-bold">CRM: </span>{{ data.crm }}</p>
        <p v-if="data.crm_uf"><span class="font-weight-bold">CRM UF: </span>{{ data.crm_uf }}</p>
        <div class="d-flex justify-content-around mt-2">          
          <b-button variant="danger" @click="reprove(data.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Reprovar</span>
          </b-button>
          <b-button class="mr-1" variant="success" @click="aprove(data.id)">
              <span class="align-middle ml-50">Aprovar</span>
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip,} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isInfoSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    participante: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
        email: null,
        cpf: null,
        secretaria: null,
        crm: null,
        crm_uf: null
      },
      previewUrl: null
    }
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        this.previewUrl = null;
      }
    },
    reprove(id) {
        this.$swal({
          title: 'Deseja reprovar esta solicitação?',
          text: 'Você não será capaz de reverte essa ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b65f0',
          cancelButtonColor: '#c1c1c1',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim, desejo reprovar!',
          showLoaderOnConfirm: true,
          preConfirm: () => axios.post(`${process.env.VUE_APP_API}/events/request-solicitation/action/reproved/${id}`)
              .then(response => {
                console.log(response.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao apagar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              }),
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Reprovado!',
              'Participante reprovado.',
              'success'
            )
          }
        })
      },
    aprove(id) {
      // /return console.log(id)
        this.$swal({
          title: 'Deseja aprovar esta solicitação?',
          text: 'Você não será capaz de reverte essa ação!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b65f0',
          cancelButtonColor: '#c1c1c1',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim, desejo aprovar!',
          showLoaderOnConfirm: true,
          preConfirm: () => axios.post(`${process.env.VUE_APP_API}/events/request-solicitation/action/aproved/${id}`)
              .then(response => {
                
                console.log(response)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao aprovar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              }),
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              'Aprovado!',
              'Participante aprovado com sucesso.',
              'success'
            )
          }
        })
      },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        email: null,
        cpf: null,
        secretaria: null,
        cargo: null,
        crm: null,
        crm_ud: null,
      }
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const formData = new FormData()
            formData.append('id', this.form.id)
            formData.append('name', this.form.name)
            formData.append('cpf', this.form.cpf)
            formData.append('email', this.form.email)
            formData.append('secretaria', this.form.secretaria)
            formData.append('cargo', this.form.cargo)
            formData.append('crm', this.form.crm)
            formData.append('crm_uf', this.form.crm_uf)

            axios.post(`${process.env.VUE_APP_API}/events/update-participant/${this.form.id}`, formData)
              .then(response => {
                console.log(response.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao atualizar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
                this.resetForm()
                this.isInfoSidebarActive = false
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              })

            
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
  },
  mounted() {
    this.form.id = this.data.id
    this.form.name = this.data.name
    this.form.cpf = this.data.cpf
    this.form.email = this.data.email
    this.form.secretaria = this.data.secretaria
    this.form.cargo = this.data.cargo
    this.form.crm = this.data.crm
    this.form.crm_uf = this.data.crm_uf
    console.log(this.category)
  }
}

</script>

<style>

</style>