<template>
  <b-sidebar id="add-new-patrocinadores-sidebar" :visible="isAddNewSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Adicionar patrocinador</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
      </div>
      <validation-observer
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="submit()"
        >
        <validation-provider
          v-slot="{ errors }"
          name="cpf"
          rules="required"
        >
          <b-form-group
            label="CPF"
            label-for="cpf"
          >
            <b-form-input
              id="cpf"
              v-model="form.cpf"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Criar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip,} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        category_id: null,
        title: null,
        link: null,
        logo: null,
      },
      previewUrl: null
    }
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        // Cria uma instância FileReader
        const reader = new FileReader();

        // Define a função que será chamada após a leitura do arquivo
        reader.onload = (e) => {
          this.previewUrl = e.target.result; // Define a URL da prévia da imagem
        };

        // Lê o arquivo como uma URL de objeto (blob URL)
        reader.readAsDataURL(file);
      } else {
        this.previewUrl = null; // Se nenhum arquivo for selecionado, redefine a prévia como nula
      }
    },
    resetForm() {
      this.form = {
        category_id: null,
        title: null,
        link: null,
        logo: null,
      }
      this.logo_old = null,
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const formData = new FormData()
            formData.append('cpf', this.form.cpf)
            formData.append('event_id', this.$route.params.id)
            axios.post(`${process.env.VUE_APP_API}/events/store-participant`, formData)
              .then(response => {
                console.log(response.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao adicionar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
                this.resetForm()
                this.isAddNewSidebarActive = false
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              })

            
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
  },
}

</script>

<style>

</style>